<template>
  <div justify="center">
    <RegistroPacientes
      :paciente="paciente"
      :dialogPaciente="dialogPaciente"
      @cerrarModalPaciente="cerrarModalPaciente"
    ></RegistroPacientes>

    <v-dialog
      v-model="dialogHistoriaClinicaLocal"
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-system-bar height="62" color="primary" dark>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-menu</v-icon>
          </v-btn>

          <v-toolbar-title color="primary">
            <span :class="`text-body-1`" color="primary" style="color: white">
              Consulta Paciente | {{ paciente.nombre }}
              {{ paciente.primerApellido }}
            </span>
            <v-icon style="color: white" @click="mostrarModalPaciente()"
              >mdi-pencil</v-icon
            >
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn icon @click="cerrarModalHistoriaClinica()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-system-bar>

        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          app
          clipped
          permanent
        >
          <v-list dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item link v-show="false">
                <v-list-item-icon>
                  <v-icon>mdi-stethoscope</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Resumen</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-stethoscope</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Motivo de Consulta</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                v-show="configuracionHistoriaClinica.antecedentesVisible"
              >
                <v-list-item-icon>
                  <v-icon>mdi-backburger</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Antecedentes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                v-show="configuracionHistoriaClinica.estudiosRealizadosVisible"
              >
                <v-list-item-icon>
                  <v-icon>mdi-file-document-multiple-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Estudios Realizados</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                v-show="configuracionHistoriaClinica.examenFisicoVisible"
              >
                <v-list-item-icon>
                  <v-icon>mdi-clipboard-pulse-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Examen Físico</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-doctor</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Diagnóstico</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-prescription</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Indicaciones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-format-align-left</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Formularios</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-briefcase-variant-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Documentos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <v-card-text :style="marginLeft">
          <v-form @submit.prevent="guardar" ref="formhc" lazy-validation>
            <v-main>
              <v-container v-show="selectedItem === 0">
                <resumen-historia-clinica></resumen-historia-clinica>
              </v-container>

              <v-container v-show="selectedItem === 1">
                <v-layout row wrap>
                  <v-flex>
                    <v-textarea
                      xs12
                      md6
                      outlined
                      :rules="[rules.required]"
                      v-model="historiaClinica.motivoConsulta"
                      label="Motivo de Consulta"
                    ></v-textarea>

                    <v-textarea
                      v-if="
                        configuracionHistoriaClinica.historiaEnfermedadVisible
                      "
                      xs12
                      md6
                      outlined
                      v-model="historiaClinica.historiaEnfermedad"
                      label="Historia de la Enfermedad"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-container v-show="selectedItem === 2" grid-list-md>
                <v-layout row>
                  <v-flex
                    v-for="antecedente in antecedentes"
                    :key="antecedente.id"
                  >
                    <v-textarea
                      sm12
                      md3
                      v-if="antecedente.multilinea"
                      :label="antecedente.nombre"
                      v-model="antecedente.valor"
                      :append-icon="
                        antecedente.sinPatologiaAparente
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank'
                      "
                      @click:append="actualizarValorAntecedentes(antecedente)"
                      outlined
                    ></v-textarea>
                    <v-text-field
                      v-else
                      sm12
                      md6
                      :label="antecedente.nombre"
                      v-model="antecedente.valor"
                      :append-icon="
                        antecedente.sinPatologiaAparente
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank'
                      "
                      @click:append="actualizarValorAntecedentes(antecedente)"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-container v-show="selectedItem === 3" grid-list-md>
                <v-layout row>
                  <v-flex
                    v-for="estudioRealizado in estudiosRealizados"
                    :key="estudioRealizado.id"
                  >
                    <v-textarea
                      sm12
                      md6
                      v-if="estudioRealizado.multilinea"
                      :label="estudioRealizado.nombre"
                      v-model="estudioRealizado.valor"
                      :append-icon="
                        estudioRealizado.sinPatologiaAparente
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank'
                      "
                      @click:append="
                        actualizarValorEstudiosRealizados(estudioRealizado)
                      "
                      outlined
                    ></v-textarea>
                    <v-text-field
                      v-else
                      sm12
                      md3
                      :label="estudioRealizado.nombre"
                      v-model="estudioRealizado.valor"
                      :append-icon="
                        estudioRealizado.sinPatologiaAparente
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank'
                      "
                      @click:append="
                        actualizarValorEstudiosRealizados(estudioRealizado)
                      "
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-container v-show="selectedItem === 4" grid-list-md>
                <v-layout row>
                  <v-flex
                    v-for="examenFisicoItem in examenFisico"
                    :key="examenFisicoItem.id"
                  >
                    <v-textarea
                      sm12
                      md3
                      v-if="examenFisicoItem.multilinea"
                      :label="examenFisicoItem.nombre"
                      v-model="examenFisicoItem.valor"
                      :append-icon="
                        examenFisicoItem.sinPatologiaAparente
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank'
                      "
                      @click:append="
                        actualizarValorExamenFisico(examenFisicoItem)
                      "
                      outlined
                    ></v-textarea>
                    <v-text-field
                      v-else
                      sm12
                      md3
                      :label="examenFisicoItem.nombre"
                      v-model="examenFisicoItem.valor"
                      :append-icon="
                        examenFisicoItem.sinPatologiaAparente
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank'
                      "
                      @click:append="
                        actualizarValorExamenFisico(examenFisicoItem)
                      "
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-container v-show="selectedItem === 5">
                <v-row>
                  <v-col md="12px">
                    <v-combobox
                      outlined
                      dense
                      v-model="historiaClinica.diagnosticos"
                      :filter="filtrarDiagnosticos"
                      :hide-no-data="!search"
                      :items="diagnosticos"
                      :search-input.sync="search"
                      class="upperCase"
                      hide-selected
                      label="Seleccione uno o varios diagnósticos"
                      multiple
                      small-chips
                      @keydown.enter="guardarDiagnostico(0, search)"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <span class="subheading">
                            No se encontró
                            <v-chip label small>{{ search }}</v-chip
                            >presione <strong>ENTER</strong> para crear el
                            diagnóstico
                          </span>
                        </v-list-item>
                      </template>
                      <template
                        v-slot:selection="{ attrs, item, parent, selected }"
                      >
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">{{ item.text }}</span>
                          <v-icon small @click="parent.selectItem(item)"
                            >close</v-icon
                          >
                        </v-chip>
                      </template>
                      <template v-slot:item="{ index, item }">
                        <v-text-field
                          v-if="editing === item"
                          v-model="editing.text"
                          autofocus
                          flat
                          class="upperCase"
                          background-color="transparent"
                          hide-details
                          @keyup.enter="editarDiagnostico(index, item)"
                        ></v-text-field>
                        <v-chip v-else label small>{{ item.text }}</v-chip>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>
                          <v-btn
                            icon
                            @click.stop.prevent="editarDiagnostico(index, item)"
                          >
                            <v-icon>
                              {{
                                editing !== item ? "mdi-pencil" : "mdi-check"
                              }}
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <p>
                    <br />
                    <br />
                    <br />
                  </p>
                </v-row>
              </v-container>

              <v-container v-show="selectedItem === 6">
                <v-tabs>
                  <v-tabs-slider color="secondary"></v-tabs-slider>
                  <v-tab href="#tabMedicamentos">
                    <v-icon>mdi-pill</v-icon>Medicamentos
                  </v-tab>
                  <v-tab href="#tabLaboratorios">
                    <v-icon>mdi-test-tube</v-icon>Laboratorios
                  </v-tab>
                  <v-tab href="#tabImagenes">
                    <v-icon>mdi-image</v-icon>Imágenes
                  </v-tab>

                  <v-tab-item :value="tabsIndicaciones.tabMedicamentos">
                    <v-container>
                      <medicamentos-HC
                        ref="medicamentos"
                        :fechaIndicacionMedicamentos="
                          historiaClinica.fechaIndicacionMedicamentos
                        "
                        :observacionIndicacionMedicamentos="
                          historiaClinica.observacionIndicacionMedicamentos
                        "
                        :historiaClinicaId="historiaClinicaId"
                        :nombrePaciente="paciente.nombreCompleto"
                      ></medicamentos-HC>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item :value="tabsIndicaciones.tabLaboratorios">
                    <v-container>
                      <laboratorios-HC
                        :fechaIndicacionLaboratorios="
                          historiaClinica.fechaIndicacionLaboratorios
                        "
                        :observacionIndicacionLaboratorios="
                          historiaClinica.observacionIndicacionLaboratorios
                        "
                        :historiaClinicaId="historiaClinicaId"
                        ref="laboratorios"
                        :laboratororioIndicados="
                          historiaClinica.laboratoriosIndicados
                        "
                        :nombrePaciente="paciente.nombreCompleto"
                      ></laboratorios-HC>
                    </v-container>
                  </v-tab-item>

                  <v-tab-item :value="tabsIndicaciones.tabImagenes">
                    <v-container>
                      <imagenes-HC
                        :fechaIndicacionImagenes="
                          historiaClinica.fechaIndicacionImagenes
                        "
                        :observacionIndicacionImagenes="
                          historiaClinica.observacionIndicacionImagenes
                        "
                        :historiaClinicaId="historiaClinicaId"
                        :imagenesIndicadas="historiaClinica.imagenesIndicadas"
                        :nombrePaciente="paciente.nombreCompleto"
                        ref="imagenes"
                      ></imagenes-HC>
                    </v-container>
                  </v-tab-item>
                </v-tabs>
              </v-container>

              <v-container
                class="pl-0"
                grid-list-xs
                v-show="selectedItem === 7"
              >
                <v-card elevation="0">
                  <v-layout row>
                    <v-flex>
                      <v-combobox
                        v-model="formularioPersonalizadoSeleccionado"
                        :items="formulariosPersonalizados"
                        label="Seleccione el formulario deseado"
                        item-text="nombre"
                        item-value="id"
                        single-line
                        outlined
                        @change="onFormularioPersonalizadoSeleccionado"
                      ></v-combobox>
                    </v-flex>

                    <v-flex md1>
                      <v-btn
                        block
                        class="ml-2 mt-2"
                        outlined
                        rounded
                        @click="imprimirFormularioPersonalizadoFromHistoria()"
                        color="primary"
                      >
                        <v-icon color="primary" center>mdi-printer</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout md3>
                    <v-menu
                      ref="menuFecha"
                      v-model="menuFecha"
                      :return-value.sync="fecha"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          outlined
                          v-model="fecha"
                          label="Fecha"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker v-model="fecha" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuFecha = false"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuFecha.save(fecha)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-layout>
                  <v-layout
                    v-for="item in formulariosFiltrados"
                    :key="item.id"
                    row
                  >
                    <v-flex>
                      <h4>
                        {{ item.nombre }}
                      </h4>
                      <v-layout row>
                        <v-flex
                          v-for="subItem in item.subItems"
                          :key="subItem.id"
                          :class="{ lg5custom: item.subItems.length > 4 }"
                        >
                          <v-textarea
                            v-if="subItem.multilinea"
                            :label="subItem.nombre"
                            v-model="subItem.valor"
                            :append-icon="
                              subItem.sinPatologiaAparente
                                ? 'mdi-checkbox-marked'
                                : 'mdi-checkbox-blank'
                            "
                            @click:append="
                              actualizarValorFormularioPersonzalizado(subItem)
                            "
                            outlined
                          ></v-textarea>
                          <v-text-field
                            v-else
                            :label="subItem.nombre"
                            v-model="subItem.valor"
                            :append-icon="
                              subItem.sinPatologiaAparente
                                ? 'mdi-checkbox-marked'
                                : 'mdi-checkbox-blank'
                            "
                            @click:append="
                              actualizarValorFormularioPersonzalizado(subItem)
                            "
                            outlined
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-container>

              <v-container v-show="selectedItem === 8" grid-list-md>
                <expediente-digital
                  style="margin-top: -20px"
                  :paciente="paciente"
                ></expediente-digital>
              </v-container>

              <v-footer fixed
                ><v-spacer></v-spacer>

                <v-btn
                  class="pl-4"
                  small
                  xs4
                  @click="imprimirHistoria()"
                  outlined
                  rounded
                  color="primary"
                >
                  <v-icon medium>mdi-printer</v-icon>Imprimir
                </v-btn>
                <v-btn small outlined rounded type="submit" color="primary">
                  <v-icon left>mdi-check</v-icon>Guardar
                </v-btn>
                <v-btn
                  class="pl-4"
                  small
                  color="secondary"
                  outlined
                  rounded
                  @click="cerrarModalHistoriaClinica()"
                >
                  <v-icon left>mdi-close</v-icon>Cerrar
                </v-btn>
              </v-footer>
            </v-main>
          </v-form>
        </v-card-text>
      </v-card>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import MedicamentosHC from "../historiasClinicasGroup/indicaciones/MedicamentosHC.vue";
import LaboratoriosHC from "../historiasClinicasGroup/indicaciones/LaboratoriosHC.vue";
import ImagenesHC from "../historiasClinicasGroup/indicaciones/ImagenesHC.vue";
import RegistroPacientes from "@/components/pacientesGroup/RegistroPacientes.vue";
import ResumenHistoriaClinica from "../historiasClinicasGroup/ResumenHistoriaClinica.vue";
import axios from "@/shared/jwtAxios";
import ExpedienteDigital from "../pacientesGroup/ExpedienteDigital.vue";
import swal from "sweetalert";
import { dateNow, removeTimeFromDate } from "../../utility/dateHelpers.js";

export default {
  components: {
    RegistroPacientes,
    MedicamentosHC,
    LaboratoriosHC,
    ImagenesHC,
    ExpedienteDigital,
    ResumenHistoriaClinica,
  },
  name: "crear-historia-clinica",
  created() {
    this.getDiagnosticos();
    this.getFormulariosPersonalizados();
    this.getItemsFormulariosPersonalizados();

    this.getEstudiosRealizados();
    this.getExamenFisico();
    this.getConfiguracion();

    if (this.$store.state.historiaId) {
      this.getAntecedentesByHistoriaClinicaId(this.$store.state.historiaId);
    } else {
      this.getAntecedentes();
    }
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  data() {
    return {
      drawer: true,
      overlay: false,
      selectedItem: 1,
      isActive: true,
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
      mini: false,
      marker: true,
      //HISTORIA CLINICA
      url: this.$apiConfig.historiasClinicasUrl,
      tabHistoria: null,
      formularioPersonalizadoSeleccionado: "",
      formularioPersonalizadoUrl: this.$apiConfig.formularioPersonalizadoUrl,
      configuracionHistoriaClinica: "",
      historiaClinicaId: this.$store.state.historiaId,
      antecedentes: [],
      examenFisico: [],
      estudiosRealizados: [],
      medicamentosIndicados: [],
      headers: [
        {
          text: "",
          align: "start",
          value: "header",
        },
        {
          text: "esfera",
          align: "start",
          value: "esfera",
        },
        {
          text: "cilindro",
          align: "start",
          value: "cilindro",
        },
        {
          text: "eje",
          align: "start",
          value: "eje",
        },
        {
          text: "DP",
          align: "start",
          value: "dp",
        },
        {
          text: "altura",
          align: "start",
          value: "altura",
        },
      ],
      desserts: [
        {
          name: "OD",
          header: "OD",
        },
        {
          name: "OI",
          header: "OI",
        },
      ],
      //DIAGNOSTICO
      diagnosticoUrl: this.$apiConfig.diagnosticoUrl,
      diagnostico: {
        id: 0,
        descripcion: "",
        text: "",
      },
      editing: null,
      editingIndex: -1,
      fecha: dateNow(),
      menuFecha: false,
      nonce: 1,
      search: "",
      diagnosticos: [],
      formulariosPersonalizadosItems: [],
      formulariosPersonalizados: [],
      tabsIndicaciones: {
        tabMedicamentos: "tabMedicamentos",
        tabLaboratorios: "tabLaboratorios",
        tabImagenes: "tabImagenes",
        tabLentes: "tabLentes",
      },
      dialogHistoriaClinicaLocal: this.dialogHistoriaClinica,
      //PACIENTE
      dialogPaciente: false,
      //VALIDACIONES
      rules: {
        required: (valido) => !!valido || this.$camposRequeridosMessage,
      },
    };
  },
  props: {
    paciente: Object,
    dialogHistoriaClinica: Boolean,
    historiaClinica: Object,
  },
  computed: {
    formulariosFiltrados() {
      const arr = this.formulariosPersonalizadosItems || [];
      return arr.filter((formulario) => {
        return (
          formulario.formularioPersonalizadoId ===
          this.formularioPersonalizadoSeleccionado.id
        );
      });
    },
    marginLeft() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "margin-left: -8px;";
        case "sm":
          return "margin-left: -8px;";
        case "md":
          return "margin-left: 0px;";
        case "lg":
          return "margin-left: -200px;";
        case "xl":
          return "margin-left: -200px;";
      }
    },
  },
  watch: {
    dialogHistoriaClinica: function (newValue) {
      this.dialogHistoriaClinicaLocal = newValue;
    },
    fechaIndicacionFormularioPersonalizado: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== null && newValue !== undefined) {
          this.fecha = removeTimeFromDate(newValue) || dateNow();
        }
      },
    },
  },
  destroyed() {
    this.limpiarIndicaciones();
    this.limpiarFormulariosPersonalizadosItems();
    this.limpiarHistoriaClinicaId();
    this.limpiar();
  },
  methods: {
    imprimirFormularioPersonalizadoFromHistoria() {
      this.historiaClinica.paciente = this.paciente;
      this.historiaClinica.paciente.fechaNacimiento = new Date().toISOString();
      this.historiaClinica.cuentaId = this.$store.state.cuentaId.toString();

      this.historiaClinica.items = this.formulariosPersonalizadosItems;

      this.historiaClinica.nombreReporte =
        this.formularioPersonalizadoSeleccionado.nombreReporte;

      this.historiaClinica.FormularioPersonalizadoId =
        this.formularioPersonalizadoSeleccionado.id;

      this.historiaClinica.fechaFormularioPersonalizado = this.fecha;
      // this.historiaClinica.fecha = dateNowDDMMYY();

      axios({
        url: `${this.formularioPersonalizadoUrl}/imprimir`,
        method: "POST",
        responseType: "blob",
        data: this.historiaClinica,
      }).then((response) => {
        let d = new Date();
        let n = d.toLocaleTimeString();
        let fileName = `${this.historiaClinica.paciente.nombreCompleto}${n}.pdf`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        window.console.log(link);
      });

      // let _array = JSON.stringify(this.formulariosPersonalizadosItems);
      // let params = {
      //   nombrePaciente: "pedro", //this.nombrePaciente,
      //   fecha: "",
      //   observacion: "",
      //   nombreReporte: "DraKarlinaMinguezPrescripcionLentes", //this.$store.state.reporteImagenes,
      //   array: _array,
      // };
      // window.console.log(_array);
      // // window.console.log(`${this.formularioPersonalizadoUrl}/imprimir/`);

      // imprimir(
      //   `${this.formularioPersonalizadoUrl}/imprimir/`,
      //   params,
      //   "pedro" //this.nombrePaciente
      // );

      // axios({
      //   url: `${this.formularioPersonalizadoUrl}/imprimir/`, //your url
      //   method: "GET",
      //   responseType: "blob",
      //   params: params,
      //   // important
      // }).then((response) => {
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", "file.pdf"); //or any other extension
      //   document.body.appendChild(link);
      //   link.click();
      // });
    },
    getIndex(array, id) {
      array.findIndex((object) => {
        return object.id === id;
      });
    },
    actualizarValorAntecedentes(antecedente) {
      window.console.log(antecedente);
      let index = this.antecedentes.findIndex((object) => {
        return object.id === antecedente.id;
      });
      window.console.log(index);
      this.antecedentes[index].sinPatologiaAparente =
        !antecedente.sinPatologiaAparente;

      if (this.antecedentes[index].sinPatologiaAparente)
        this.antecedentes[index].valor = antecedente.valorPredeterminado;
      else
        this.antecedentes[index].valor = antecedente.valorPredeterminado = "";
    },
    actualizarValorEstudiosRealizados(estudioRealizado) {
      window.console.log(estudioRealizado);

      let index = this.estudiosRealizados.findIndex((object) => {
        return object.id === estudioRealizado.id;
      });

      this.estudiosRealizados[index].sinPatologiaAparente =
        !estudioRealizado.sinPatologiaAparente;

      this.estudiosRealizados[index].valor = this.estudiosRealizados[index]
        .sinPatologiaAparente
        ? estudioRealizado.valorPredeterminado
        : "";
    },
    actualizarValorExamenFisico(examenFisicoItem) {
      window.console.log(examenFisicoItem);
      let index = this.examenFisico.findIndex((object) => {
        return object.id === examenFisicoItem.id;
      });

      this.examenFisico[index].sinPatologiaAparente =
        !examenFisicoItem.sinPatologiaAparente;

      this.examenFisico[index].valor = this.estudiosRealizados[index]
        .sinPatologiaAparente
        ? examenFisicoItem.valorPredeterminado
        : "";
    },
    actualizarValorFormularioPersonzalizado(subItem) {
      let itemIndex = this.formulariosPersonalizadosItems.findIndex(
        (object) => {
          return object.id === subItem.formularioPersonalizadoItemId;
        }
      );
      let subItemIndex = this.formulariosPersonalizadosItems[
        itemIndex
      ].subItems.findIndex((object) => {
        return object.id === subItem.id;
      });

      this.formulariosPersonalizadosItems[itemIndex].subItems[
        subItemIndex
      ].sinPatologiaAparente = !subItem.sinPatologiaAparente;

      if (
        this.formulariosPersonalizadosItems[itemIndex].subItems[subItemIndex]
          .sinPatologiaAparente
      ) {
        this.formulariosPersonalizadosItems[itemIndex].subItems[
          subItemIndex
        ].valor = subItem.valorPredeterminado;
      } else {
        this.formulariosPersonalizadosItems[itemIndex].subItems[
          subItemIndex
        ].valor = "";
      }
    },
    //HISTORIA CLINICA
    cerrarModalHistoriaClinica() {
      this.tabHistoria = null;
      this.$emit("cerrarModalHistoriaClinica");
      this.$refs.formhc.resetValidation();
      this.limpiarIndicaciones();
      this.$store.commit("clearFechaIndicacionMedicamentos");
      this.$store.commit("clearFechaIndicacionLaboratorios");
      this.$store.commit("clearFechaIndicacionImagenes");
    },
    //PACIENTE
    cerrarModalPaciente() {
      this.dialogPaciente = false;
    },
    guardar() {
      try {
        this.overlay = true;
        this.historiaClinica.paciente = this.paciente;
        this.historiaClinica.paciente.fechaNacimiento =
          new Date().toISOString();
        this.historiaClinica.cuentaId = this.$store.state.cuentaId.toString();
        this.historiaClinica.items = this.formulariosPersonalizadosItems;
        this.historiaClinica.antecedentes = this.antecedentes;
        this.historiaClinica.estudiosRealizados = this.estudiosRealizados;
        this.historiaClinica.examenFisico = this.examenFisico;

        this.historiaClinica.diagnosticos =
          this.historiaClinica.diagnosticos.filter(
            (d) => typeof d !== "string"
          );
        this.historiaClinica.medicoActualId =
          this.$store.getters.getMedicoId.toString();
        this.historiaClinica.medicamentosIndicados =
          this.$store.state.medicamentos || [];
        this.historiaClinica.laboratoriosIndicados =
          this.$store.state.laboratorios.filter((l) => typeof l !== "string");
        this.historiaClinica.imagenesIndicadas =
          this.$store.state.imagenes.filter((i) => typeof i !== "string");

        this.historiaClinica.fechaIndicacionMedicamentos =
          this.$store.state.fechaIndicacionMedicamentos;
        this.historiaClinica.fechaIndicacionLaboratorios =
          this.$store.state.fechaIndicacionLaboratorios;
        this.historiaClinica.fechaIndicacionImagenes =
          this.$store.state.fechaIndicacionImagenes;

        this.historiaClinica.observacionIndicacionMedicamentos =
          this.$store.state.observacionIndicacionMedicamentos;
        this.historiaClinica.observacionIndicacionImagenes =
          this.$store.state.observacionIndicacionImagenes;
        this.historiaClinica.observacionIndicacionLaboratorios =
          this.$store.state.observacionIndicacionLaboratorios;

        if (this.$refs.formhc.validate()) {
          axios
            .post(this.url, this.historiaClinica)
            .then((response) => {
              if (response.status === 200) {
                this.historiaClinica.id = response.data;
                this.overlay = false;
                this.cerrarModalHistoriaClinica();
                swal({
                  title: "Información",
                  text: "¡Datos guardados correctamente!",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              this.overlay = false;
              swal({
                title: "Error",
                text: "¡Error al guardar!",
                icon: "error",
              });
              window.console.log(error.response);
            });
        } else {
          this.$completarCamposRequeridosAlert();
          this.overlay = false;
        }
      } catch (error) {
        window.console.error(error);
      }
    },
    //DIAGNOSTICO
    editarDiagnostico(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
        item.text = item.text.toUpperCase();
        this.guardarDiagnostico(item.id, item.text);
      }
    },
    filtrarDiagnosticos(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");
      const descripcion = hasValue(itemText);
      const query = hasValue(queryText);
      return (
        descripcion
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },

    getAntecedentesByHistoriaClinicaId(historiaClinicaId) {
      axios
        .get(
          `${this.url}/getAntecedentesByHistoriaClinica/${historiaClinicaId}`
        )
        .then((response) => {
          this.antecedentes = response.data;
          if (
            this.antecedentes.length <= 0 ||
            this.antecedentes === null ||
            this.antecedentes === undefined
          ) {
            this.getAntecedentes();
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getAntecedentes() {
      axios
        .get(
          this.url + "/getAntecedentes/" + this.$store.state.cuentaId.toString()
        )
        .then((response) => {
          this.antecedentes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getEstudiosRealizadossByHistoriaClinicaId(historiaClinicaId) {
      axios
        .get(
          `${this.url}/getEstudiosRealizadosByHistoriaClinica/${historiaClinicaId}`
        )
        .then((response) => {
          this.estudiosRealizados = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getEstudiosRealizados() {
      if (this.$store.state.historiaId) {
        this.getEstudiosRealizadossByHistoriaClinicaId(
          this.$store.state.historiaId
        );
      } else {
        axios
          .get(
            this.url +
              "/getEstudiosRealizados/" +
              this.$store.state.cuentaId.toString()
          )
          .then(({ data }) => {
            this.estudiosRealizados = data;
          })
          .catch((error) => {
            window.console.log(error);
          });
      }
    },
    getExamenFisicoByHistoriaClinicaId(historiaClinicaId) {
      axios
        .get(
          `${this.url}/getExamenFisicoByHistoriaClinica/${historiaClinicaId}`
        )
        .then((response) => {
          this.examenFisico = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getExamenFisico() {
      if (this.$store.state.historiaId) {
        this.getExamenFisicoByHistoriaClinicaId(this.$store.state.historiaId);
      } else {
        axios
          .get(
            this.url +
              "/getExamenFisico/" +
              this.$store.state.cuentaId.toString()
          )
          .then(({ data }) => {
            this.examenFisico = data;
          })
          .catch((error) => {
            window.console.log(error);
          });
      }
    },
    getDiagnosticos() {
      axios
        .get(this.diagnosticoUrl)
        .then(({ data }) => {
          this.diagnosticos = data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getItemsByHistoriaClinicaId(historiaClinicaId) {
      axios
        .get(
          `${this.$apiConfig.formularioPersonalizadoUrl}/getItemsByHistoriaClinica/${historiaClinicaId}`
        )
        .then((response) => {
          this.formulariosPersonalizadosItems = response.data;
          this.$store.commit(
            "setFormulariosPersonalizadosItems",
            response.data
          );
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getItemsFormulariosPersonalizados() {
      if (this.$store.state.historiaId) {
        this.getItemsByHistoriaClinicaId(this.$store.state.historiaId);
      } else {
        axios
          .get(this.formularioPersonalizadoUrl + "/getItems")
          .then(({ data }) => {
            this.formulariosPersonalizadosItems = data;
          })
          .catch((error) => {
            window.console.log(error);
            this.$errorCargarDatos();
          });
      }
    },
    getConfiguracion() {
      axios
        .get(this.url + "/getConfiguracion/" + this.$store.state.cuentaId)
        .then(({ data }) => {
          this.configuracionHistoriaClinica = data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getFormulariosPersonalizados() {
      axios
        .get(this.formularioPersonalizadoUrl)
        .then(({ data }) => {
          this.formulariosPersonalizados = data;
          this.formularioPersonalizadoSeleccionado =
            this.formulariosPersonalizados[0];
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    guardarDiagnostico(id, descripcion) {
      this.diagnostico = {
        id: id,
        descripcion: descripcion.toUpperCase(),
        text: descripcion.toUpperCase(),
      };
      axios
        .post(this.diagnosticoUrl, this.diagnostico)
        .then(() => {
          if (id === 0) {
            this.historiaClinica.diagnosticos.pop();
            //GET LAST DIAGNOSTICO
            axios
              .get(this.diagnosticoUrl + "/getLast")
              .then(({ data }) => {
                this.historiaClinica.diagnosticos.pop();
                this.historiaClinica.diagnosticos.push(data);
              })
              .catch((error) => {
                window.console.log(error);
              });
          }
          this.$datosGuardados();
          this.getDiagnosticos();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    imprimirHistoria() {
      this.historiaClinica.paciente = this.paciente;
      this.historiaClinica.paciente.fechaNacimiento = new Date().toISOString();
      this.historiaClinica.cuentaId = this.$store.state.cuentaId.toString();
      this.historiaClinica.formularios = this.formulariosPersonalizados;
      this.historiaClinica.items = this.formulariosPersonalizadosItems;
      this.historiaClinica.antecedentes = this.antecedentes;
      this.historiaClinica.estudiosRealizados = this.estudiosRealizados;
      this.historiaClinica.examenFisico = this.examenFisico;
      this.historiaClinica.nombreReporte =
        this.$store.state.reporteHistoriaClinica;

      this.historiaClinica.diagnosticos =
        this.historiaClinica.diagnosticos.filter((d) => typeof d !== "string");
      this.historiaClinica.medicoActualId =
        this.$store.getters.getMedicoId.toString();
      this.historiaClinica.medicamentosIndicados =
        this.$store.state.medicamentos || [];
      this.historiaClinica.laboratoriosIndicados =
        this.$store.state.laboratorios.filter((l) => typeof l !== "string");
      this.historiaClinica.imagenesIndicadas =
        this.$store.state.imagenes.filter((i) => typeof i !== "string");

      this.historiaClinica.fechaIndicacionMedicamentos =
        this.$store.state.fechaIndicacionMedicamentos;
      this.historiaClinica.fechaIndicacionLaboratorios =
        this.$store.state.fechaIndicacionLaboratorios;
      this.historiaClinica.fechaIndicacionImagenes =
        this.$store.state.fechaIndicacionImagenes;

      axios({
        url: `${this.url}/imprimir`,
        method: "POST",
        responseType: "blob",
        data: this.historiaClinica,
      }).then((response) => {
        let d = new Date();
        let n = d.toLocaleTimeString();
        let fileName = `${this.historiaClinica.paciente.nombreCompleto}${n}.pdf`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        window.console.log(link);
      }).catch((error) => {
        window.console.log(error);
      });
    },
    limpiarIndicaciones() {
      this.$store.state.medicamentos = [];
      this.$store.state.laboratorios = [];
      this.$store.state.imagenes = [];
      this.historiaClinica.medicamentosSeleccionados = [];
      this.historiaClinica.laboratoriosSeleccionados = [];
      this.historiaClinica.imagenesSeleccionadas = [];
    },
    limpiarFormulariosPersonalizadosItems() {
      this.$store.state.formulariosPersonalizadosItems = [];
      this.historiaClinica.items = [];
    },
    limpiar() {
      this.antecedentes = [];
      this.estudiosRealizados = [];
      this.examenFisico = [];
    },
    limpiarHistoriaClinicaId() {
      this.$store.state.historiaId = "";
    },
    mostrarModalPaciente() {
      this.dialogPaciente = true;
    },
    onFormularioPersonalizadoSeleccionado() {
      //window.console.log(this.formularioPersonalizadoSeleccionado);
      //window.console.log(this.formulariosFiltrados);
    },
  },
};
</script>

<style>
@import url("../../../src/css/myCss.css");

@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>
