export default class ApiConfig {
  constructor() {
    //this.base = "http://backend.sigemed.com.do/";
    //this.base = "https://localhost:44350/";
    //this.base = "http://localhost:5000/";
     //this.base = "https://localhost:44350/";
    this.base = "https://backend.sigemed.com.do/";

    //URLS DE PRUEBA
    // this.base = "http://appbackendtest.ioen.org.do/";

    this.articulosUrl = `${this.base}api/Articulos`;
    this.centrosMedicosUrl = `${this.base}api/CentrosMedicos`;
    this.citaUrl = `${this.base}api/Cita`;
    this.cuentasUrl = `${this.base}api/Cuentas`;
    this.configuracionUrl = `${this.base}api/Configuracion`;
    this.diagnosticoUrl = `${this.base}api/Diagnostico`;
    this.especialidadesUrl = `${this.base}api/Especialidades`;
    this.facturasUrl = `${this.base}api/Facturas`;
    this.formularioPersonalizadoUrl = `${this.base}api/FormularioPersonalizado`;
    this.historiasClinicasUrl = `${this.base}api/HistoriaClinica`;
    this.imagenesUrl = `${this.base}api/Imagenes`;
    this.laboratoriosUrl = `${this.base}api/Laboratorios`;
    this.loginUrl = `${this.base}api/Autenticacion/Login`;
    this.medicamentosUrl = `${this.base}api/Medicamentos`;
    this.pacientesUrl = `${this.base}api/Pacientes`;
    this.turnosUrl = `${this.base}api/TurnosPacientes`;
    this.planesUrl = `${this.base}api/Planes`;
    this.segurosMedicosUrl = `${this.base}api/SegurosMedicos`;
    this.usuariosUrl = `${this.base}api/Usuarios`;
    this.tokensUrl = `${this.base}api/Tokens`;
    this.medicosUrl = `${this.base}api/Medicos`;
    this.rolesUsuariosUrl = `${this.base}api/RolesUsuarios`;
    this.plantillaIndicacionMedicamentos = `${this.base}api/PlantillaIndicacionMedicamentos`;
    this.plantillaIndicacionImagenes = `${this.base}api/PlantillaIndicacionImagenes`;
    this.plantillaIndicacionLaboratorios = `${this.base}api/PlantillaIndicacionLaboratorios`;
    this.expedienteDigitalUrl = `${this.base}api/ExpedienteDigital`;

  }
}
